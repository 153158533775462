import React from "react";
import ReactPlayer from "react-player";
import { connect } from "react-redux";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import styles from "./video-only.module.css";
import { completeModule } from "../../state/actions";

let playerRef;

const FosterFamilySupports = props => (
  <Layout>
    <SEO
      title="Module - Foster Family Supports"
      keywords={["utah foster care", "foster care"]}
    />
    <div className={styles.moduleGridContainer}>
      <h1 className={styles.videoTitle}>Foster Family Supports</h1>
      <div className={styles.container}>
        <ReactPlayer
          ref={c => (playerRef = c)}
          className={styles.reactPlayer}
          url="https://www.youtube.com/embed/2p2YMmwaNYU"
          controls
          height="100%"
          width="100%"
          onEnded={() => {
            playerRef.seekTo(0);
            props.markComplete();
          }}
        />
      </div>
    </div>
  </Layout>
);

const stateToProps = () => ({});

const dispatchToProps = dispatch => {
  return {
    markComplete() {
      dispatch(completeModule(8));
    }
  };
};

export default connect(
  stateToProps,
  dispatchToProps
)(FosterFamilySupports);
